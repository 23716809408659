@tailwind base;
@tailwind components;
@tailwind utilities;

.cube-container {
  perspective: 1000px;
  width: 200px;
  height: 200px;
}

.cube {
  position: relative;
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transform: rotateX(30deg) rotateY(-45deg);
  animation: rotate 10s infinite linear;
}

.edge {
  position: absolute;
  background: rgba(255, 255, 255, 0.9);
  border: 2px solid rgba(0, 0, 0, 0.1);
  width: 10px;
  height: 200px;
}

.edge1 { transform: rotateX(90deg) translateZ(100px); }
.edge2 { transform: rotateX(-90deg) translateZ(100px); }
.edge3 { transform: rotateY(90deg) translateZ(100px); }
.edge4 { transform: rotateY(-90deg) translateZ(100px); }
.edge5 { transform: translateX(100px) translateY(-100px); }
.edge6 { transform: translateX(100px) translateY(100px); }
.edge7 { transform: translateX(-100px) translateY(-100px); }
.edge8 { transform: translateX(-100px) translateY(100px); }
.edge9 { transform: rotateX(45deg) translateZ(100px) translateY(-100px); }
.edge10 { transform: rotateX(-45deg) translateZ(100px) translateY(-100px); }
.edge11 { transform: rotateY(45deg) translateZ(100px) translateY(-100px); }
.edge12 { transform: rotateY(-45deg) translateZ(100px) translateY(-100px); }

@keyframes rotate {
  from {
    transform: rotateX(30deg) rotateY(-45deg);
  }
  to {
    transform: rotateX(30deg) rotateY(315deg);
  }
}
